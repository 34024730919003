import classNames from 'classnames'
import gsap, {Linear} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {ReactNode, useEffect, useRef} from 'react'

// import ResizeObserver from 'resize-observer-polyfill'
import styles from './MarqueeV2.module.scss'

gsap.registerPlugin(ScrollTrigger)

export interface MarqueeV2 {
  children: ReactNode
  className?: string
  speed?: number
  maxVelocity?: number
  velocityFactor?: number
  accellerationDuration?: number
  reverseOnScrollUp?: boolean
  isReversed?: boolean
}

const MarqueeV2 = ({
  children,
  className,
  speed = 75,
  isReversed = false,
}: MarqueeV2): JSX.Element => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const tweenRef = useRef<GSAPTween>()

  useEffect(() => {
    const section = sectionRef.current
    const container = containerRef.current
    const child = container ? container.firstElementChild : null

    function initAnimation() {
      if (section && container && child) {
        clearAnimation()
        cloneContent(section, container, child as HTMLDivElement)

        const distance = child.clientWidth
        const time = distance / speed

        gsap.set(container.children, {
          flex: '0 0 auto',
          marginLeft: 0,
          marginRight: 0,
        })

        // Marquee Animation
        const tween = gsap.to(container.children, {
          repeat: -1,
          x: (isReversed ? '+' : '-') + distance,
          ease: Linear.easeNone,
          duration: time,
          onReverseComplete: function () {
            this.totalTime(time * 100 + this.rawTime())
          },
        })

        // Store tween & scrollTrigger
        tweenRef.current = tween
      }
    }

    function cloneContent(
      section: HTMLDivElement,
      container: HTMLDivElement,
      child: HTMLDivElement
    ) {
      // Clear clones
      const clones = container.querySelectorAll('[data-is-clone="true"]')
      clones.forEach((el) => el.remove())

      // Get amount of clones needed
      const count = Math.ceil(section.clientWidth / child.clientWidth) + 1

      if (count !== Infinity) {
        // Create clones
        for (let i = 0; i < count; i++) {
          const clone = child.cloneNode(true) as HTMLElement
          clone.dataset.isClone = 'true'
          clone.ariaHidden = 'true'
          container.appendChild(clone)
        }
      }
    }

    function clearAnimation() {
      if (tweenRef.current) tweenRef.current.progress(0).pause().revert().kill()
    }

    initAnimation()

    return () => {
      clearAnimation()
    }
  }, [])

  return (
    <div ref={sectionRef} className={classNames(styles.marquee, className)}>
      <div
        ref={containerRef}
        className={classNames(
          styles.container,
          {[styles.justend]: isReversed},
          {[styles.juststart]: !isReversed}
        )}
      >
        <div className={styles.inline}>{children}</div>
      </div>
    </div>
  )
}

export default MarqueeV2
