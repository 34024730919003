import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect} from 'react'
import {MARK_ITALIC, render} from 'storyblok-rich-text-react-renderer'

import {TSplitWithImageV2} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import Button from '@/components/Button'
import StoryblokImageV2 from '@/components/StoryblokImageV2/StoryblokImageV2'

import styles from './SplitWithImageV2.module.scss'

const Shape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 704 792' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M704 0H396C177.295 0 0 177.295 0 396C0 614.705 177.295 792 396 792H704V0Z'
        fill='currentColor'
      />
    </svg>
  )
}

const SplitWithImageV2 = ({
  title,
  heading,
  paragraph,
  btnLabel,
  btnLink,
  image,
  videoHevc,
  videoWebm,
  // video,
  isFlipped,
  theme,
}: TSplitWithImageV2): JSX.Element => {
  const hasVideo = videoHevc && videoHevc.filename && videoWebm && videoWebm.filename

  useEffect(() => {
    const timelines: gsap.core.Timeline[] = []
    const rollers = Array.from(document.querySelectorAll(`.${styles.roller}`))

    rollers.forEach((roller) => {
      const timeline = gsap.timeline({repeat: -1})
      const items = Array.from(roller.querySelectorAll(`.${styles.item}`))
      items.forEach((item) => {
        timeline.fromTo(
          item,
          {yPercent: -90, opacity: 0},
          {yPercent: -50, opacity: 1, duration: 0.3}
        )
        timeline.to(item, {
          opacity: 0,
          yPercent: -20,
          duration: 0.3,
          delay: 0.8,
        })
      })

      timelines.push(timeline)
    })

    return () => {
      timelines.forEach((tl) => tl.kill())
    }
  }, [])

  return (
    <section
      className={classNames(
        styles.section,
        {[styles.hasBg]: theme === 'background'},
        {[styles.hasNoBg]: theme === 'none'},
        {[styles.ailice]: theme === 'ailice'},
        {[styles.ailiceDarkblue]: theme === 'ailiceDarkblue'},
        {[styles.isFlipped]: isFlipped}
      )}
    >
      {theme === 'ailice' && <div className={styles.rectAilice}></div>}

      {theme === 'ailiceDarkblue' && <div className={styles.rectAiliceDarkblue}></div>}

      {title && (
        <div className={styles.container}>
          <div className={styles.contenttitle}>
            {title && <h2 className={styles.title}>{title}</h2>}
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          {heading && (
            <h2 className={classNames(styles.heading, styles.headingPrimary)}>
              {render(heading, {
                ...unwrapRenderParagraph,
                markResolvers: {
                  [MARK_ITALIC]: function Italic(children: React.ReactNode) {
                    if (typeof children === 'string') {
                      const words = children.split(' ')
                      const filteredWords = words.filter((word) => word !== '')

                      const longestWord = filteredWords.reduce(
                        (longest, current) => (current.length > longest.length ? current : longest),
                        ''
                      )

                      return (
                        <>
                          <span className={styles.roller}>
                            <span aria-hidden className={styles.placeholder}>
                              {longestWord + ' '}
                            </span>
                            {filteredWords.map((word, index) => {
                              return (
                                <span key={index} className={styles.item}>
                                  {word}
                                </span>
                              )
                            })}
                          </span>
                          <br />
                        </>
                      )
                    }

                    return <>{children}</>
                  },
                },
              })}
            </h2>
          )}

          {paragraph && (
            <p className={styles.paragraph}>{render(paragraph, unwrapRenderParagraph)}</p>
          )}

          {btnLink && btnLabel && (
            <div className={styles.button}>
              <Button link={btnLink} label={btnLabel} />
            </div>
          )}
        </div>

        {image && image.filename && (
          <div className={styles.picture}>
            <Shape className={styles.shape} preserveAspectRatio='none' />

            {!hasVideo && image && image.filename && (
              <StoryblokImageV2
                className={styles.image}
                filename={image.filename}
                alt={image.alt}
                loading='lazy'
                sizes={{lg: 50}}
              />
            )}

            {hasVideo && (
              <video className={styles.image} autoPlay loop muted playsInline preload='auto'>
                <source src={videoHevc.filename} type='video/mp4; codecs="hvc1"' />
                <source src={videoWebm.filename} type='video/webm' />
              </video>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default SplitWithImageV2
