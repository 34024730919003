import React from 'react'
import SbEditable from 'storyblok-react'

import {componentTypes} from '@/common/enums'
import {TBlok} from '@/common/types'
import Accordion from '@/components/Accordion'
import Card from '@/components/Card'
import CardNumbers from '@/components/CardNumbers'
import CardSlider from '@/components/CardSlider'
import CardSliderItem from '@/components/CardSliderItem'
import Channels from '@/components/Channels'
import ChannelsV2 from '@/components/ChannelsV2'
// import ChannelsV3 from '@/components/ChannelsV3'
import CtaCentered from '@/components/CtaCentered'
import CtaDouble from '@/components/CtaDouble'
import CtaSimple from '@/components/CtaSimple'
import CtaWithImage from '@/components/CtaWithImage'
import DraggableCardsList from '@/components/DraggableCardsList'
import Features from '@/components/Features'
import FeaturesAi from '@/components/FeaturesAi'
import FeaturesAiV2 from '@/components/FeaturesAiV2'
import Footer from '@/components/Footer'
import FooterAddress from '@/components/Footer/FooterAddress'
import FooterItem from '@/components/Footer/FooterItem'
import GridCards from '@/components/GridCards'
import HeroComplex from '@/components/HeroComplex'
import HeroHome from '@/components/HeroHome'
import HeroHomeV2 from '@/components/HeroHomeV2'
import HeroSimple from '@/components/HeroSimple'
import HeroSimpleV2 from '@/components/HeroSimplev2'
import HeroWithPartnerLogo from '@/components/HeroWithPartnerLogo'
import Image from '@/components/Image'
import IndustryCards from '@/components/IndustryCards'
import IndustryCard from '@/components/IndustryCards/IndustryCard'
import LogoList from '@/components/LogoList'
import Logos from '@/components/Logos'
import LogosV2 from '@/components/LogosV2'
import LogoWall from '@/components/LogoWall'
import MasonryCard from '@/components/MasonryCard'
import MasonryCards from '@/components/MasonryCards'
import Navigation from '@/components/Navigation'
import NavigationV2 from '@/components/NavigationV2'
import Review from '@/components/Review'
import ScrollingTitle from '@/components/ScrollingTitle'
import Spacer from '@/components/Spacer'
import SplitListWithImageV2 from '@/components/SplitListWithImageV2/SplitListWithImageV2'
import SplitWithImage from '@/components/SplitWithImage'
import SplitWithImageFullWidth from '@/components/SplitWithImageFullWidth'
import SplitWithImageV2 from '@/components/SplitWithImageV2'
import Testimonial from '@/components/Testimonial'
import TestimonialCardsV2 from '@/components/TestimonialCardsV2'
import TestimonialItem from '@/components/TestimonialItem'
import Title from '@/components/Title'
import TitleV2 from '@/components/TitleV2'
import TitleWithParagraph from '@/components/TitleWithParagraph'
import VerticalSlider from '@/components/VerticalSlider'
import VerticalSliderItem from '@/components/VerticalSliderItem'
import Video from '@/components/Video'
import WithFullWidthImage from '@/components/WithFullWidthImage'
import WithImageOverlap from '@/components/WithImageOverlap'
import Wizard from '@/components/Wizard'

const Component = (props: TBlok): JSX.Element => {
  switch (props.component) {
    case componentTypes.ACCORDION:
      return <Accordion {...props} />
    case componentTypes.CARD:
      return <Card {...props} />
    case componentTypes.CARD_NUMBERS:
      return <CardNumbers {...props} />
    case componentTypes.CARD_SLIDER:
      return <CardSlider {...props} />
    case componentTypes.CARD_SLIDER_ITEM:
      return <CardSliderItem {...props} />
    case componentTypes.CHANNELS:
      return <Channels {...props} />
    case componentTypes.CHANNELS_V2:
      return <ChannelsV2 {...props} />
    case componentTypes.CTA_CENTERED:
      return <CtaCentered {...props} />
    case componentTypes.CTA_DOUBLE:
      return <CtaDouble {...props} />
    case componentTypes.CTA_SIMPLE:
      return <CtaSimple {...props} />
    case componentTypes.CTA_WITH_IMAGE:
      return <CtaWithImage {...props} />
    case componentTypes.DRAGGABLE_CARDS_LIST:
      return <DraggableCardsList {...props} />
    case componentTypes.FEATURES:
      return <Features {...props} />
    case componentTypes.FEATURESAI:
      return <FeaturesAi {...props} />
    case componentTypes.FEATURES_AI_V2:
      return <FeaturesAiV2 {...props} />
    case componentTypes.FOOTER:
      return <Footer {...props} />
    case componentTypes.FOOTER_ADDRESS:
      return <FooterAddress {...props} />
    case componentTypes.FOOTER_ITEM:
      return <FooterItem {...props} />
    case componentTypes.GRID_CARDS:
      return <GridCards {...props} />
    case componentTypes.HERO_COMPLEX:
      return <HeroComplex {...props} />
    case componentTypes.HERO_HOME:
      return <HeroHome {...props} />
    case componentTypes.HERO_HOME_V2:
      return <HeroHomeV2 {...props} />
    case componentTypes.HERO_SIMPLE:
      return <HeroSimple {...props} />
    case componentTypes.HERO_SIMPLE_V2:
      return <HeroSimpleV2 {...props} />
    case componentTypes.HERO_WITH_PARTNER_LOGO:
      return <HeroWithPartnerLogo {...props} />
    case componentTypes.IMAGE:
      return <Image {...props} />
    case componentTypes.INDUSTRY_CARDS:
      return <IndustryCards {...props} />
    case componentTypes.INDUSTRY_CARD:
      return <IndustryCard {...props} />
    case componentTypes.LOGO_LIST:
      return <LogoList {...props} />
    case componentTypes.LOGOS:
      return <Logos {...props} />
    case componentTypes.LOGOS_V2:
      return <LogosV2 {...props} />
    case componentTypes.LOGO_WALL:
      return <LogoWall {...props} />
    case componentTypes.MASONRY_CARD:
      return <MasonryCard {...props} />
    case componentTypes.MASONRY_CARDS:
      return <MasonryCards {...props} />
    case componentTypes.NAVIGATION:
      return <Navigation {...props} />
    case componentTypes.NAVIGATION_V2:
      return <NavigationV2 {...props} />
    case componentTypes.REVIEW:
      return <Review {...props} />
    case componentTypes.SCROLLING_TITLE:
      return <ScrollingTitle {...props} />
    case componentTypes.SPACER:
      return <Spacer {...props} />
    case componentTypes.SPLIT_LIST_WITH_IMAGE_V2:
      return <SplitListWithImageV2 {...props} />
    case componentTypes.SPLIT_WITH_IMAGE:
      return <SplitWithImage {...props} />
    case componentTypes.SPLIT_WITH_IMAGE_V2:
      return <SplitWithImageV2 {...props} />
    case componentTypes.SPLIT_WITH_IMAGE_FULL_WIDTH:
      return <SplitWithImageFullWidth {...props} />
    case componentTypes.TESTIMONIAL:
      return <Testimonial {...props} />
    case componentTypes.TESTIMONIALCARDSV2:
      return <TestimonialCardsV2 {...props} />
    case componentTypes.TESTIMONIAL_ITEM:
      return <TestimonialItem {...props} />
    case componentTypes.TESTIMONIAL_REFERENCE:
      return <TestimonialItem {...props} />
    case componentTypes.TITLE:
      return <Title {...props} />
    case componentTypes.TITLE_V2:
      return <TitleV2 {...props} />
    case componentTypes.TITLE_WITH_PARAGRAPH:
      return <TitleWithParagraph {...props} />
    case componentTypes.VERTICAL_SLIDER:
      return <VerticalSlider {...props} />
    case componentTypes.VERTICAL_SLIDER_ITEM:
      return <VerticalSliderItem {...props} />
    case componentTypes.VIDEO:
      return <Video {...props} />
    case componentTypes.WITH_FULL_WIDTH_IMAGE:
      return <WithFullWidthImage {...props} />
    case componentTypes.WITH_IMAGE_OVERLAP:
      return <WithImageOverlap {...props} />
    case componentTypes.WIZARD:
      return <Wizard {...props} />
    default:
      return <div />
  }
}

const DynamicComponent = (props: TBlok): JSX.Element => {
  return (
    <SbEditable content={props}>
      <Component {...props} />
    </SbEditable>
  )
}

export default DynamicComponent
