import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect} from 'react'
import {MARK_ITALIC, render} from 'storyblok-rich-text-react-renderer'

import {TSplitWithImageFullWidth} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import Button from '@/components/Button'
import StoryblokImageV2 from '@/components/StoryblokImageV2/StoryblokImageV2'

import styles from './SplitWithImageFullWidth.module.scss'

const Shape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='626'
      height='627'
      viewBox='0 0 626 627'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M625.551 313.523C625.551 486.14 485.617 626.074 313 626.074C140.383 626.074 0.448822 486.14 0.448822 313.523C0.448822 140.906 140.383 0.97171 313 0.97171C485.617 0.97171 625.551 140.906 625.551 313.523Z'
        fill='url(#paint0_linear_1_4)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_4'
          x1='739.368'
          y1='449.396'
          x2='-28.2985'
          y2='641.582'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6BC8EE' />
          <stop offset='0.786458' stopColor='#D9D3FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

// const ShapeMobile = (props: React.SVGProps<SVGSVGElement>) => {
//   return (
//     <svg viewBox='0 0 311 216' fill='none' xmlns='http://www.w3.org/2000/svg'>
//       <path
//         d='M0.25 108.153C0.25 48.7906 48.3728 0.667725 107.735 0.667725H310.432V215.639H107.735C48.3728 215.639 0.25 167.516 0.25 108.153Z'
//         fill='url(#paint0_linear_1103_81426)'
//       />
//       <defs>
//         <linearGradient
//           id='paint0_linear_1103_81426'
//           x1='366.909'
//           y1='154.88'
//           x2='8.8351'
//           y2='284.227'
//           gradientUnits='userSpaceOnUse'
//         >
//           <stop stopColor='#6BC8EE' />
//           <stop offset='0.786458' stopColor='#D9D3FF' />
//         </linearGradient>
//       </defs>
//     </svg>
//   )
// }

const SplitWithImageFullWidth = ({
  heading,
  paragraph,
  btnLabel,
  btnLink,
  image,
  isFlipped,
  theme,
}: TSplitWithImageFullWidth): JSX.Element => {
  useEffect(() => {
    const timelines: gsap.core.Timeline[] = []
    const rollers = Array.from(document.querySelectorAll(`.${styles.roller}`))

    rollers.forEach((roller) => {
      const timeline = gsap.timeline({repeat: -1})
      const items = Array.from(roller.querySelectorAll(`.${styles.item}`))
      items.forEach((item) => {
        timeline.fromTo(
          item,
          {yPercent: -90, opacity: 0},
          {yPercent: -50, opacity: 1, duration: 0.3}
        )
        timeline.to(item, {
          opacity: 0,
          yPercent: -20,
          duration: 0.3,
          delay: 0.8,
        })
      })

      timelines.push(timeline)
    })

    return () => {
      timelines.forEach((tl) => tl.kill())
    }
  }, [])

  return (
    <section
      className={classNames(
        styles.section,
        {[styles.lightTheme]: theme === 'light'},
        {[styles.darkTheme]: theme === 'dark'},
        {[styles.isFlipped]: isFlipped}
      )}
    >
      {/* {theme === 'ailice' && <div className={styles.rectAilice}></div>}
      {theme === 'ailiceDarkblue' && <div className={styles.rectAiliceDarkblue}></div>} */}

      <div className={styles.shapeContainer}>
        <Shape className={styles.shape} preserveAspectRatio='none' />
        <div className={styles.rectAilice}></div>
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          {heading && (
            <h2 className={classNames(styles.heading, styles.headingPrimary)}>
              {render(heading, {
                ...unwrapRenderParagraph,
                markResolvers: {
                  [MARK_ITALIC]: function Italic(children: React.ReactNode) {
                    if (typeof children === 'string') {
                      const words = children.split(' ')
                      const filteredWords = words.filter((word) => word !== '')

                      const longestWord = filteredWords.reduce(
                        (longest, current) => (current.length > longest.length ? current : longest),
                        ''
                      )

                      return (
                        <>
                          <span className={styles.roller}>
                            <span aria-hidden className={styles.placeholder}>
                              {longestWord + ' '}
                            </span>
                            {filteredWords.map((word, index) => {
                              return (
                                <span key={index} className={styles.item}>
                                  {word}
                                </span>
                              )
                            })}
                          </span>
                          <br />
                        </>
                      )
                    }

                    return <>{children}</>
                  },
                },
              })}
            </h2>
          )}
          {paragraph && (
            <p className={styles.paragraph}>{render(paragraph, unwrapRenderParagraph)}</p>
          )}
          {btnLink && btnLabel && (
            <div className={styles.button}>
              <Button link={btnLink} label={btnLabel} />
            </div>
          )}
        </div>
      </div>

      {image && image.filename && (
        <div className={styles.picturecontainer}>
          <div className={styles.picture}>
            <div className={styles.imagewrapper}>
              <div className={styles.imageinnerwrapper}>
                {image && image.filename && (
                  <StoryblokImageV2
                    className={styles.image}
                    filename={image.filename}
                    alt={image.alt}
                    loading='lazy'
                    sizes={{lg: 50}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default SplitWithImageFullWidth
