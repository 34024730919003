import {render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {TTestimonialCardsV2} from '@/common/types'
import StoryblokImageV2 from '@/components/StoryblokImageV2/StoryblokImageV2'

import styles from './TestimonialCardsV2.module.scss'

const TestimonialCardsV2 = ({title, cards = []}: TTestimonialCardsV2): JSX.Element => {
  return (
    <section className={styles.section}>
      {title && <h2 className={styles.heading}>{title}</h2>}

      <div className={styles.container}>
        <div className={styles.wrapper}>
          {cards.map((card) => {
            if (card.component !== componentTypes.TESTIMONIALCARD) return null
            const {title, unit, description, logo} = card
            return (
              <div key={card._uid} className={styles.card}>
                {(title || unit) && (
                  <p className={styles.titlewrapper}>
                    {title && <span className={styles.title}>{title}</span>}
                    {unit && <span className={styles.unit}>{unit}</span>}
                  </p>
                )}
                {description && <div className={styles.description}>{render(description)}</div>}
                {logo && logo.filename && (
                  <div className={styles.logo}>
                    <StoryblokImageV2 filename={logo.filename} alt={logo.alt} />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TestimonialCardsV2
