import {useRouter} from 'next/router'
import {useEffect} from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {THeroHomeV2} from '@/common/types'
// import MarqueeV2 from '@/components/MarqueeV2/MarqueeV2'
// import StoryblokImage from '@/components/StoryblokImage/StoryblokImage'
import StoryblokImageV2 from '@/components/StoryblokImageV2/StoryblokImageV2'

import styles from './HeroHomeV2.module.scss'

export type TFormData = {
  firstname?: string
  lastname?: string
  phone?: string
  email?: string
}

type hbsptCreate = {
  portalId?: string
  formId?: string
  target?: string
}

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: ({portalId, formId, target}: hbsptCreate) => void
      }
    }
  }
}

const HeroHomeV2 = ({
  heading,
  subtitle,
  authorImage,
  authorName,
  authorRole,
  // images = [],
  formTitle = '',
  formID = '',
}: THeroHomeV2): JSX.Element => {
  const router = useRouter()

  useEffect(() => {
    if (formID !== '') {
      const hubspotFormJs = document.createElement('script')
      hubspotFormJs.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(hubspotFormJs)

      hubspotFormJs.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: '19665323',
            formId: formID,
            target: '#hubspotForm',
          })
        }
      })
    }
  }, [])

  useEffect(() => {
    const handleSwHubspotform = () => {
      if (formID !== '' && window.hbspt) {
        window.hbspt.forms.create({
          portalId: '19665323',
          formId: formID,
          target: '#hubspotForm',
        })
      }
    }

    // router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleSwHubspotform)
    router.events.on('routeChangeError', handleSwHubspotform)

    return () => {
      // router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleSwHubspotform)
      router.events.off('routeChangeError', handleSwHubspotform)
    }
  }, [router])

  return (
    <section className={styles.section}>
      <div className={styles.shapes}>
        <div className={styles.shapeleft}></div>
        <div className={styles.shaperight}></div>
      </div>
      <div className={styles.container}>
        <div className={styles.textcontent}>
          {heading && (
            <svg width='54' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                opacity='.4'
                d='M25.571 0 12.033 30.56l-1.504-9.184c2.708 0 4.964.954 6.769 2.86 1.905 1.807 2.858 4.065 2.858 6.775 0 3.211-1.053 5.87-3.159 7.978C14.891 40.996 12.434 42 9.627 42c-2.708 0-5.014-.903-6.92-2.71C.904 37.383 0 35.075 0 32.366c0-1.606.25-3.111.752-4.517.602-1.505 1.504-3.261 2.708-5.268L16.095 0h9.476ZM54 0 40.462 30.56l-1.504-9.184c2.708 0 4.964.954 6.769 2.86 1.905 1.807 2.858 4.065 2.858 6.775 0 3.211-1.053 5.87-3.159 7.978C43.32 40.996 40.863 42 38.056 42c-2.708 0-5.014-.903-6.92-2.71-1.805-1.907-2.707-4.215-2.707-6.924 0-1.606.25-3.111.752-4.517.602-1.505 1.504-3.261 2.708-5.268L44.524 0H54Z'
                fill='#F7F6FB'
              />
            </svg>
          )}
          {heading && <h2 className={styles.heading}>{render(heading)}</h2>}
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          {(authorImage || authorName || authorRole) && (
            <div className={styles.testimonialAuthor}>
              {authorImage && authorImage.filename && (
                <div className={styles.authorPP}>
                  <StoryblokImageV2
                    filename={authorImage.filename}
                    alt={authorImage.alt}
                    sizes={{sm: 64, unit: 'px'}}
                    width='64'
                    fixedWidth={64}
                  />
                </div>
              )}
              {(authorName || authorRole) && (
                <div className={styles.authorDetail}>
                  {authorName && <p className={styles.authorName}>{authorName}</p>}
                  {authorRole && <p className={styles.authorRole}>{authorRole}</p>}
                </div>
              )}
            </div>
          )}
          {/* {images && images.length > 0 && (
            <div className={styles.marquee}>
              <MarqueeV2>
                <div className={styles.innermarquee}>
                  {images.map(({_uid, image, component}: TImage) => {
                    if (!image || typeof image === 'string' || component !== 'image') return null
                    return (
                      <div key={_uid} className={styles.item}>
                        <StoryblokImage src={image.filename} alt={image.alt} />
                      </div>
                    )
                  })}
                </div>
              </MarqueeV2>
            </div>
          )} */}
        </div>

        {formID !== '' && (
          <div className={styles.formcontent}>
            <div className={styles.formwrapper}>
              <div className={styles.formcontainer}>
                {formTitle && (
                  <div className={styles.formtitle}>
                    <p>{formTitle}</p>
                  </div>
                )}
                <div id='hubspotForm' className={styles.hbsptform}></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default HeroHomeV2
