import gsap from 'gsap'
// import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// import Image from 'next/image'
import {useEffect, useRef} from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {TLogoWall} from '@/common/types'
import Link from '@/components/Link'
import StoryblokImageV2 from '@/components/StoryblokImageV2/StoryblokImageV2'

import styles from './LogoWall.module.scss'

const LogoWall = ({title, logos = [], labelLink, link}: TLogoWall): JSX.Element => {
  const section = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const logosRowsRefs = useRef<(HTMLDivElement | null)[]>([])
  const logosRefs = useRef<(HTMLDivElement | null)[]>([])

  const storelogosRows = (e: HTMLDivElement | null, i: number) => {
    logosRowsRefs.current[i] = e
  }
  const storelogos = (e: HTMLDivElement | null, i: number) => {
    logosRefs.current[i] = e
  }

  useEffect(() => {
    if (section.current) {
      const context = gsap.context(() => {
        gsap.set(logosRowsRefs.current[0], {x: -40})
        // gsap.set([logosRowsRefs.current[1], logosRowsRefs.current[3], logosRowsRefs.current[5]], {
        //   x: -40,
        // })
        const titlewidth = titleRef.current ? titleRef.current.clientWidth / 2 + 60 : 330
        const logolist = logosRefs.current
        const topleft = logolist.slice(16, 20)
        const topright = logolist.slice(20, 24)
        const midleft = logolist.slice(24, 28)
        const midright = logolist.slice(28, 32)
        const bottomleft = logolist.slice(32, 36)
        const bottomright = logolist.slice(36, 40)

        // const tl = gsap.timeline({
        //   scrollTrigger: {
        //     trigger: section.current,
        //     start: 'top 50%',
        //     // markers: true,
        //   },
        // })
        // tl.to(topleft, {x: -titlewidth * 0.75, duration: 0.4}, 0)
        // tl.to(topright, {x: titlewidth * 0.75, duration: 0.4}, 0)
        // tl.to(midleft, {x: -titlewidth, duration: 0.4}, 0.08)
        // tl.to(midright, {x: titlewidth, duration: 0.4}, 0.08)
        // tl.to(bottomleft, {x: -titlewidth * 0.75, duration: 0.4}, 0.16)
        // tl.to(bottomright, {x: titlewidth * 0.75, duration: 0.4}, 0.16)
        // tl.to(titleRef.current, {opacity: 1, duration: 0.4}, 0.16)

        gsap.set(topleft, {x: -titlewidth * 0.75})
        gsap.set(topright, {x: titlewidth * 0.75})
        gsap.set(midleft, {x: -titlewidth})
        gsap.set(midright, {x: titlewidth})
        gsap.set(bottomleft, {x: -titlewidth * 0.75})
        gsap.set(bottomright, {x: titlewidth * 0.75})
        gsap.set(titleRef.current, {opacity: 1})
      }, section.current)
      return () => context.revert()
    }
  }, [])

  return (
    <section className={styles.section} ref={section}>
      {logos && logos.length > 0 && (
        <div className={styles.logowrap}>
          {logos.map(({_uid, logos}, i) => {
            if (logos.length <= 0) return null
            const slicedlogos = logos.slice(0, 8)
            return (
              <div key={_uid} className={styles.logorow} ref={(e) => storelogosRows(e, i)}>
                {slicedlogos.map((logo, j) => {
                  return (
                    <div
                      key={`${_uid}-logo-${j}`}
                      className={styles.logo}
                      ref={(e) => storelogos(e, 8 * i + j)}
                    >
                      <div className={styles.logoinner}>
                        {logo.filename && logo.filename !== '' && (
                          <StoryblokImageV2
                            filename={logo.filename}
                            alt='logo alt'
                            sizes={{sm: 140, unit: 'px'}}
                            width='124'
                            height='44'
                            fixedWidth={124}
                          />
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.wrapper}>
          {(title || labelLink) && (
            <div className={styles.titlelinkwrapper} ref={titleRef}>
              {title && <div className={styles.title}>{render(title)}</div>}
              {labelLink && (
                <Link link={link} className={styles.link}>
                  {labelLink}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default LogoWall
